import React, { useEffect } from "react";
import { navigate, useIntl, FormattedMessage } from "gatsby-plugin-intl";
import moment from "moment";

import * as constants from "../../app.constant";
import { numberToMoneyFormat } from "../../app.utils";
import AppLayout from "../../components/shared/AppLayout";
import planStore from "../../store/planStore";
import returnStore from "../../store/returnStore";

const PageAccountSummary = () => {
    const intl = useIntl();
    const messages = intl.messages;
    const subscriptions = returnStore.subscriptions;

    const occupationOptions = [
        {
            label: messages["reviewOrder.form.occupationField.options.0.label"],
            value: "0",
        },
        {
            label: messages["reviewOrder.form.occupationField.options.1.label"],
            value: "1",
        },
    ];

    const incomeSourceOptions = [
        {
            label: messages["reviewOrder.form.incomeSourceField.options.0.label"],
            value: "0",
        },
        {
            label: messages["reviewOrder.form.incomeSourceField.options.1.label"],
            value: "1",
        },
        {
            label: messages["reviewOrder.form.incomeSourceField.options.2.label"],
            value: "2",
        },
    ];

    useEffect(() => {
        if (!returnStore.subscriptions.length > 0) {
            navigate(constants.ROUTES.RETURN);
        }
    }, []);

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} isBackBtn={true} hasFooter={true} prevUrl={constants.ROUTES.RETURN}>

            <div className="app-page page-account-summary">

                <div className="account-summary">

                    <h2 className="account-summary__title container">
                        <FormattedMessage id="accountSummary.title" />
                    </h2>

                    {subscriptions.map((subscription, index) => {
                        const key = `${ subscription.orderNumber }-${ index }`;
                        const occupation = occupationOptions?.[subscription.contact.occupation.classification]?.label;
                        const sourceOfIncome = incomeSourceOptions?.[subscription.sourceOfIncome]?.label;

                        return(
                            <div className="account-summary__subscription" key={key}>
                                <div className="container">
                                    <div className="account-summary__subscription-title text-bold">
                                        <FormattedMessage id="accountSummary.subscription.title" /> {index+1}:
                                    </div>

                                    <h2 className="account-summary__subscription-description">
                                        <FormattedMessage id="accountSummary.subscription.description" />
                                    </h2>

                                    <div className="account-summary__row">
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.deviceDetails.phoneModel" />
                                            </div>
                                            <div>{subscription.deviceModel}</div>
                                        </div>    
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.deviceDetails.imeiSn" />
                                            </div>
                                            <div>{subscription.deviceId}</div>
                                        </div>
                                    </div>

                                    <div className="account-summary__row">
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.planDetails.planDuration" />
                                            </div>
                                            <div>{planStore.productTenure} {planStore.productTenureType}</div>
                                        </div>
                                        <div className="account-summary__table-title">
                                            <FormattedMessage id="accountSummary.subscription.planDetails.coverage.title" />
                                        </div>
                                        <table className="account-summary__table table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <FormattedMessage id={`accountSummary.subscription.planDetails.coverage.table.${subscription.planType}.0.subject`} />
                                                    </td>
                                                    <td rowSpan="2">
                                                        <FormattedMessage id={`accountSummary.subscription.planDetails.coverage.table.${subscription.planType}.0.value`} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <FormattedMessage id={`accountSummary.subscription.planDetails.coverage.table.${subscription.planType}.1.subject`} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.planDetails.planAmount" />
                                            </div>
                                            <div>{planStore.currency} {numberToMoneyFormat(subscription.planPrice)}</div>
                                        </div>
                                    </div>

                                    <div className="account-summary__row">
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.personalDetails.0.subject" />
                                            </div>
                                            <div>{subscription.contact.firstName}</div>
                                        </div>
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.personalDetails.1.subject" />
                                            </div>
                                            <div>{subscription.contact.ids[0].number}</div>
                                        </div>
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.personalDetails.2.subject" />
                                            </div>
                                            <div>{moment(subscription.contact.dateOfBirth).clone().format(constants.DATE_FORMAT)}</div>
                                        </div>
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.personalDetails.3.subject" />
                                            </div>
                                            <div>{subscription.contact.phoneNumber}</div>
                                        </div>
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.personalDetails.4.subject" />
                                            </div>
                                            <div>{subscription.contact.email}</div>
                                        </div>
                                    </div>

                                    <div className="account-summary__row">
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.extendedPersonalDetails.0.subject" />
                                            </div>
                                            <div>{subscription.contact.addresses[0].area}</div>
                                        </div>
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.extendedPersonalDetails.1.subject" />
                                            </div>
                                            <div>{subscription.contact.addresses[0].district}</div>
                                        </div>
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.extendedPersonalDetails.2.subject" />
                                            </div>
                                            <div>{subscription.contact.addresses[0].zipCode}</div>
                                        </div>
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.extendedPersonalDetails.3.subject" />
                                            </div>
                                            <div>{subscription.contact.addresses[0].street}</div>
                                        </div>
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.extendedPersonalDetails.4.subject" />
                                            </div>
                                            <div>{occupation}</div>
                                        </div>
                                        <div className="account-summary__label-value-row">
                                            <div>
                                                <FormattedMessage id="accountSummary.subscription.extendedPersonalDetails.5.subject" />
                                            </div>
                                            <div>{sourceOfIncome}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                </div>

            </div>

        </AppLayout>
    )

}

export default PageAccountSummary
