import React from "react";

import AppLayout from "../../../components/shared/AppLayout";

const PageTermsAndConditions02 = () => {
    return (
        <AppLayout hasHeader={true} isBackBtn={true} hasFooter={true}>
            <div className="app-page page-terms-and-conditions">

                <div className="terms-and-conditions">

                    <div className="container">

                        <p className="terms-and-conditions__title">
                            <strong>消費者網路投需求聲明事項</strong>
                        </p>

                        <div className="terms-and-conditions__wrapper">
                            <p>一. 我已正確提供投保所需之個人基本資料。</p>
                            <p>二. 我已瞭解所投保之商品之投保條件，符合我的投保條件、投保目的及需求。</p>
                            <p>三. 我已充分瞭解所交保費係用以購買保險商品。</p>
                            <p>四. 我已充分確認欲投保之險種、保額與保費支出與自身實際需求相當。</p>
                            <p>五. 我已瞭解投保之保險契約的權利行使、變更、解除及終止之方式。</p>
                            <p>六. 我已瞭解承保公司對於本保險契約之權利、義務及責任。</p>
                            <p>七. 我已瞭解自身應負擔之保險費以及毋須負擔違約金及其他費用。</p>
                            <p>八. 我已瞭解我投保的保險契約受有保險安定基金之保障。</p>
                            <p>九. 我已瞭解承保公司因本商品所生紛爭之處理及申訴管道。</p>
                            <p>十. 我已評估自身收入、財務狀況、職業、健康狀況與保險費之負擔能力及保險金額的相當性。</p>
                            <p>十一. 我已正確提供要保人及被保險人之行動電話號碼、電子郵件信箱或其他經主管機關認可 足資傳遞電子文件之聯絡方式。</p>
                            <p>十二. 我已確認繳交保險費之資金來源為工作或營業收入、存款、及其他非為貸款或保險單借 款。</p>
                            <p>十三. 我已充分瞭解並關心我投保的保險契約之保障內容與給付項目。</p>
                        </div>

                    </div>

                </div>

            </div>
        </AppLayout>
    );
};

export default PageTermsAndConditions02;
