import React from "react";
import { Router } from "@reach/router";

import * as constants from "../app.constant";
import PageIndex from "./index";
import PagePurchase from "./purchase/index";
import PageIMEI from "./purchase/imei";
import PageReturn from "./purchase/return";
import PageAccountSummary from "./purchase/account-summary";
import PageDeviceInfoUnsuccessful from "./purchase/device-info-unsuccessful";
import PagePlans from "./purchase/plans";
import PageFrequentlyAskedQuestions from "./purchase/frequently-asked-questions";
import PageAccount from "./purchase/account";
import PageReviewOrder from "./purchase/review-order";
import PageVerifyDevicePhoto from "./purchase/verify-device-photo";
import PageVerifyWearablesPhoto from "./purchase/verify-wearables-photo";
import PageReuploadDevicePhoto from "./purchase/reupload-device-photo";
import PageUploadSuccess from "./purchase/upload-success";
import PageReuploadDevicePhotoSuccess from "./purchase/reupload-device-photo-success";
import PageActivationSuccess from "./purchase/activation-success";
import PageActivationUnsuccessful from "./purchase/activation-unsuccessful";
import PageTermsAndConditions01 from "./purchase/terms-and-conditions/01";
import PageTermsAndConditions02 from "./purchase/terms-and-conditions/02";
import PageTermsAndConditions03 from "./purchase/terms-and-conditions/03";
import PageTermsAndConditions04 from "./purchase/terms-and-conditions/04";
import PageTermsAndConditions05 from "./purchase/terms-and-conditions/05";
import PageTermsAndConditions06 from "./purchase/terms-and-conditions/06";
import PageTermsAndConditions07 from "./purchase/terms-and-conditions/07";
import PageTermsAndConditions08Premium from "./purchase/terms-and-conditions/08-premium";
import PageTermsAndConditions08Lite from "./purchase/terms-and-conditions/08-lite";
import PageTermsAndConditionsReport from "./purchase/terms-and-conditions/report";
import PageContractPreparation from "./purchase/contract-preparation";
import PageContractPreparationSuccess from "./purchase/contract-preparation-success";
import PageCustomerCollection from "./purchase/customer-collection";
import PageCustomerCollectionSuccess from "./purchase/customer-collection-success";
import PageSystemError from "./purchase/system-error";
import PageQrScan from "./purchase/qr-scan";
import PageMaintenance from "./maintenance";
import PageNoFound from "./page-not-found";

const App = () => {
    return (
        <Router basepath="/">
            <PageIndex path={constants.ROUTES.HOME} />
            <PagePurchase path={constants.ROUTES.PURCHASE} />
            <PageIMEI path={constants.ROUTES.IMEI} />
            <PageReturn path={constants.ROUTES.RETURN} />
            <PageAccountSummary path={constants.ROUTES.ACCOUNT_SUMMARY} />
            <PageDeviceInfoUnsuccessful path={constants.ROUTES.DEVICE_INFO_UNSUCCESSFUL} />
            <PagePlans path={constants.ROUTES.PLANS} />
            <PageFrequentlyAskedQuestions path={constants.ROUTES.FAQ} />
            <PageAccount path={constants.ROUTES.ACCOUNT} />
            <PageReviewOrder path={constants.ROUTES.REVIEW_ORDER} />
            <PageVerifyDevicePhoto path={constants.ROUTES.VERIFY_DEVICE_PHOTO} />
            <PageVerifyWearablesPhoto path={constants.ROUTES.VERIFY_WEARABLES_PHOTO} />
            <PageReuploadDevicePhoto path={constants.ROUTES.REUPLOAD_DEVICE_PHOTO} />
            <PageReuploadDevicePhotoSuccess path={constants.ROUTES.REUPLOAD_DEVICE_PHOTO_SUCCESS} />
            <PageUploadSuccess path={constants.ROUTES.UPLOAD_SUCCESS} />
            <PageActivationSuccess path={constants.ROUTES.ACTIVATION_SUCCESS} />
            <PageActivationUnsuccessful path={constants.ROUTES.ACTIVATION_UNSUCCESSFUL} />
            <PageTermsAndConditions01 path={constants.ROUTES.TNC01} />
            <PageTermsAndConditions02 path={constants.ROUTES.TNC02} />
            <PageTermsAndConditions03 path={constants.ROUTES.TNC03} />
            <PageTermsAndConditions04 path={constants.ROUTES.TNC04} />
            <PageTermsAndConditions05 path={constants.ROUTES.TNC05} />
            <PageTermsAndConditions06 path={constants.ROUTES.TNC06} />
            <PageTermsAndConditions07 path={constants.ROUTES.TNC07} />
            <PageTermsAndConditions08Premium path={constants.ROUTES.TNC08_PREMIUM} />
            <PageTermsAndConditions08Lite path={constants.ROUTES.TNC08_LITE} />
            <PageTermsAndConditionsReport path={constants.ROUTES.TNC_REPORT} />
            <PageContractPreparation path={constants.ROUTES.CONTRACT_PREPARATION} />
            <PageContractPreparationSuccess path={constants.ROUTES.CONTRACT_PREPARATION_SUCCESS} />
            <PageCustomerCollection path={constants.ROUTES.CUSTOMER_COLLECTION} />
            <PageCustomerCollectionSuccess path={constants.ROUTES.CUSTOMER_COLLECTION_SUCCESS} />
            <PageSystemError path={constants.ROUTES.SYSTEM_ERROR} />
            <PageQrScan path={constants.ROUTES.QR_SCAN} />
            <PageMaintenance path={constants.ROUTES.MAINTENANCE} />
            <PageNoFound path="**" />
        </Router>
    );
};

export default App;
