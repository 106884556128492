import React from "react";
import { navigate, useIntl, FormattedMessage } from "gatsby-plugin-intl";

import * as constants from "../../app.constant";
import AppLayout from "../../components/shared/AppLayout";
import errorTranslationKeyStore from "../../store/errorTranslationKeyStore";
import errorIcon from "../../assets/images/system_caution_icon.svg";

const PageSystemError = () => {

    const intl = useIntl();
    const messages = intl.messages;
    const errorMessage = messages[`system.errorKeyToErrorMessage.${errorTranslationKeyStore.translationKey}`];
 
    const navigateBack = () => {
        navigate(constants.ROUTES.PLANS);
    };

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} hasFooter={true}>
            <div className="app-page page-error">

                <div className="system-error">
                    <div className="container">
                        <div className="system-error__header">
                            <h2 className="system-error__status">
                                <img className="system-error__status-icon img-fluid" src={errorIcon} alt="" width="44" />
                                <span className="system-error__status-text text-bold">[ {errorMessage} ]</span>
                            </h2>
                        </div>
                        <p className="btn-container btn-container--center">
                            <button type="button" className="btn btn-lg btn-primary" onClick={navigateBack}>
                                <FormattedMessage id="system.error.cta" />
                            </button>
                        </p>
                    </div>
                </div>

            </div>
        </AppLayout>
    );
};

export default PageSystemError;
